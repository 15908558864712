<template>
    <div class="container_con">
      <el-card>
        <div class="search">
          <div>
            <el-button type="primary" @click="$router.push('/addApiSettings')" v-if="have_auth('/apiSettings/add')">+ 新增</el-button>
          </div>
          <div>
            <el-input icon="el-icon-search" v-model="queryParams.keywords" @keyup.enter.native="handleQuery()" placeholder="（上游）公司名称 / AppId" clearable
                style="width:300px;margin-right:10px"></el-input>
            <el-button type="primary" @click="handleQuery()">搜索</el-button>
            <el-button @click="resetQuery()">重置</el-button>
          </div>
        </div>
        <div style="margin-top:20px">
          <el-table :data="list"  v-loading="loading" style="width: 100%">
            <el-table-column prop="title" label="（上游）公司名称"  />
            <el-table-column prop="contact_person" label="联系人" />
            <el-table-column prop="contact_number" label="联系电话"  width="110"/>
            <el-table-column prop="product_prefix" label="产品前缀" />
            <el-table-column prop="query_days" label="数据天数" />
            <el-table-column prop="self_no" label="自己的代码" />
            <el-table-column prop="self_name" label="自己的名称" />
            <el-table-column prop="app_id" label="AppId" />
            <el-table-column prop="app_secret" label="AppSecret" />
            <el-table-column prop="update_time" label="更新时间" width="150"/>
            <el-table-column fixed="right" label="操作" width="150">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="handleUpdate(scope.row)" v-if="have_auth('/apiSettings/edit')">编辑</el-button>
                <el-button type="text" size="small" @click="handleDelete(scope.row)" v-if="have_auth('/apiSettings/del')">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
  </template>
  
  <script>
  export default {
    name: "list",
    data() {
      return {
        // 列表模板
        loading: false,
        // 查询参数
        queryParams: {
            page: 1,
            size: 10,
            keywords: ''
        },
        total:0,
        list:[],
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        is_export:false
      };
    },
    created() {
      this.queryParams=window[this.$route.path] || {
        page: 1,
        size: 10,
        keywords: ''
      }
      this.getList();
    },
    watch: {
      $route(to,form) {
          window[form.path]=this.queryParams
      },
    },
    methods: {
      resetQuery(){
            this.queryParams= {
                page: 1,
                size: 10,
                keywords: ''
            }
            this.getList()
      },
      handleMerge(){
          
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id);
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        this.$router.push('/addApiSettings?obj='+JSON.stringify(row))
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const _this=this;
        const ids = row.id || this.ids;
        this.$confirm('是否确认删除?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function() {
            
            _this.$httpGet("/backend/api.Settings/delete", {id:ids}).then((res) => {
                if (res.status == 200) {
                    _this.getList();
                    _this.$message.success(res.message);
                } else {
                    _this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
          })
      },
      /** 查询列表 */
      getList() {
          this.loading = true;
          this.$httpGet("/backend/api.Settings/index", this.queryParams).then((res) => {
              if (res.status == 200) {
                  this.loading = false;
                  this.list = res.data.data;
                  this.total = res.data.total;
              } else {
                  this.$message.error(res.message);
              }
              this.loading = false;
          }).catch((err) => {
              console.log(err);
              this.loading = false;
          });
      },
      /** 搜索按钮操作 */
      handleQuery() {
          this.queryParams.page = 1;
          this.getList();
      }
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  